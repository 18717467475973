require("script-loader!js-cookie");

$(document).ready(function() {
    var cookieName = 'qr_currency';
    var defaultCurrency = 'eur';

    var currentCurrency = Cookies.get(cookieName);
    if (!currentCurrency) currentCurrency = defaultCurrency;

    changeCurrency(currentCurrency);

    function changeCurrency(currency) {
        $('.currency-button').removeClass('selected');
        $('.currency-button[data-value='+currency+']').addClass('selected');

        $(".pricer").each(function() {
            var value = $(this).attr('data-price-' + currency);
            if (value) {
                $(this).text(value);
            }
        });

        $(".tooltiped").each(function() {
            var text = $(this).attr("data-original-title");
            var $html = $("<div>").html(text);
            $html.hide();
            var $priceItems = $html.find(".pricer");
            if ($priceItems.length > 0) {
                $priceItems.each(function() {
                    var value = $(this).attr('data-price-' + currency);
                    if (value) {
                        $(this).text(value);
                    }
                });

                $(this).attr("data-original-title", $html.html());
            }
        });

    }

    $('.currency-button').click(function() {
        var currency = $(this).attr('data-value');
        if (!currency) currency = 'eur';

        Cookies.set(cookieName, currency);
        changeCurrency(currency);
    })

});
